import React from "react"
import styled from "styled-components"

import Layout from "../shared/Layout/layout"
import SEO from "../components/seo"

import { ImageBlock } from "../shared/Block/block"
import { StaticImage } from "gatsby-plugin-image"

import {
  ReactToWidth,
  ReactToWidthAuto,
} from "../shared/ReactToWidth/react_to_width"

const ZoomOnHover = styled.div`
  -webkit-backface-visibility: hidden;

  transition: 1.4s all ease;
  clip-path: circle(40% at 50% 48%);

  &:hover {
    transition-duration: 0.8s;
    clip-path: circle(47% at 49% 48%);
  }
`

const ColorizeOnHover = styled.div`
  -webkit-backface-visibility: hidden;

  transition: 2s all ease;
  filter: sepia(45%) grayscale(20%);

  &:hover {
    transition-duration: 2s;
    filter: sepia(7%) grayscale(15%);
  }
`
const UpContrastOnHover = styled.div`
  -webkit-backface-visibility: hidden;

  transition: 3s all ease;
  filter: contrast(80%) brightness(1.1);

  &:hover {
    transition-duration: 0.8s;
    filter: contrast(105%) brightness(1);
  }
`

const TranslateOnHover = styled.div`
  transition: 3s all ease;
  will-change: transform;

  &:hover {
    transform: translate(0, -1.5%);
    transition-duration: 0.8s;
  }
`

const Portrait = () => (
  <div style={{ textAlign: `center` }}>
    <ZoomOnHover>
      <ColorizeOnHover>
        <UpContrastOnHover>
          <TranslateOnHover>
            <StaticImage
              src="../images/henri-portrait.jpg"
              alt="henri"
              width={320}
              layout="fixed"
              placeholder="blurred"
              quality="90"
            // quality="100"
            />
          </TranslateOnHover>
        </UpContrastOnHover>
      </ColorizeOnHover>
    </ZoomOnHover>
  </div>
)

const WorkHistoryMarkingStyle = styled(ReactToWidthAuto)`
  margin: 0.5rem 0;
  font-family: nunito sans;
`

const WorkHistoryMarking = ({ year, text }) => (
  <WorkHistoryMarkingStyle
    // min_width="20rem"
    flex_direction="row"
    max_width="40rem"
    flex_direction_alternative="column"
  >
    <div
      style={{
        backgroundColor: `var(--color-primary-02)`,
        padding: `0.5rem 1rem`,
        margin: `0 1rem 0 0`,
        // margin: `0`,
        display: `flex`,
        width: `9rem`,
        minWidth: `9rem`,
        justifyContent: `flex-start`,
        borderRadius: `0.15rem`,
      }}
    >
      {year}
    </div>
    <div
      style={{
        display: `flex`,
        minWidth: `12rem`,
        justifyContent: `left`,
        alignItems: `center`,
        // paddingLeft: `1rem`,
      }}
    >
      {text}
    </div>
  </WorkHistoryMarkingStyle>
)

// <ImageBlock margin_bottom="6rem">
/*
      image_gradient="no-repeat linear-gradient(
          135deg,
          var(--color-invalid) 0%,
          rgb(40, 60, 140) 25%,
          var(--color-invalid) 50%,
          var(--color-shock-2) 85%)"
    >*/
const AboutMePage = () => (
  <Layout>
    <SEO title="Minusta" />
    <ImageBlock
      margin_bottom="0rem"
      image_gradient="no-repeat radial-gradient(
        circle at center,
        var(--color-primary-04) 10%,
        var(--color-primary-02) 85%
      )"
      min_height="50rem"
    >
      <ReactToWidth
        max_width="1000px"
        flex_direction_alternative="column-reverse"
        align_items="center"
        style={{ marginBottom: `3rem` }}
      >
        <div>
          <h1>Hieman minusta</h1>
          <p style={{ maxWidth: `40rem` }}>
            Nimeni on Henri Immonen ja olen Team Immosen perustaja. Vapaa-ajalla
            olen intohimoinen koodari ja pysyttelen tietotekniikan
            aallonharjalla kehittämällä jatkuvasti osaamistani. Olen toiminut
            ohjelmistokehittäjänä jo puolen vuosikymmenen ajan seuraavissa
            yrityksissä:
          </p>
        </div>
        <div>
          <Portrait />
        </div>
      </ReactToWidth>

      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          marginTop: `2rem`,
        }}
      >
        <ReactToWidthAuto max_width="55rem">
          <WorkHistoryMarking year="2016 - 2017" text="EasyAntiCheat Oy" />
          <WorkHistoryMarking year="2018 - 2019" text="Intel Finland Oy" />
          <WorkHistoryMarking year="2019 - 2021" text="MOST Digital Oy" />
          <WorkHistoryMarking year="2021 -" text="Team Immonen" />
          <WorkHistoryMarking year="2021 -" text="Productivity Leap Oy" />
        </ReactToWidthAuto>
      </div>
      <div style={{ marginBottom: `6rem` }} />
    </ImageBlock>
  </Layout>
)
/*
a in such companies as: Easy Anticheat, Intel and MOST Digital, the latter being my current employer.*/
/*
I work in the latter as a software robotics developer while not working as a 
While not working as a web developer I  I currently The where I still work
with the title of a software robotics developer.aspectRatio={1}
*/
// style={{ clipPath: `circle(20% at 50% 23%)` }}

export default AboutMePage
